export default function Activity3Rules() {
  return (
    <>
      <p style={{ textAlign: "center" }}>健达新年倒数日历活动规则</p>
      <br />

      <p>一、活动主题</p>
      <p>健达新年倒数日历（以下称“本活动”）</p>
      <br />

      <p>二、活动时间</p>
      <p>2023年12月10日00：00—2024年2月29日24：00</p>
      <br />

      <p>三、奖品设置</p>
      <p>健达福利社会员积分50分</p>

      <br />
      <p>四、参与方式</p>
      <p>
        1.活动期间，消费者购买龙年版“健达新年倒数日历装巧克力制品”（以下称“活动产品”），扫描活动产品包装上任意日期窗格背面二维码进入活动页面，即可观看1条与孩子“年”在一起的妙招并点亮相应日期。
      </p>
      <p>
        2.健达会员点亮全部15个日期后，可直接领取50积分；非会员则需按页面提示，注册为健达会员后，领取50积分。每个健达会员限领取一次，同一个手机号码、微信号、会员ID视为同一会员。
      </p>
      <br />

      <p>五、获奖须知</p>
      <p>
        1.健达会员积分将于获奖后48小时内发放至获奖者的会员账户中，会员积分可兑换的福利和使用方式详见“健达福利社”微信小程序。
      </p>
      <p>2.奖项一经确认不得转换、转让、折换现金或其他权益。</p>
      <p>3.奖品个人所得税由主办方代扣代缴。</p>
      <br />
      <p>六、其他</p>
      <p>1.本次活动主办方为费列罗贸易（上海）有限公司（统一简称“主办方”）。</p>
      <p>
        2.活动参与者应仔细阅读本活动须知，参与本活动则视为已阅读、知晓并同意遵守本次活动规则。
      </p>
      <p>
        3.主办不对因网络传输原因而导致参加者提交的信息错误承担任何责任。如遇不可抗力、网络突发故障、系统发生故障或遭受第三方攻击及其他主办方无法控制的情形，在法律允许的范围内，主办方对前述情形所导致的一后果不承担任何责任，并有权相应地取消、终止、修改、暂停或延迟本次活动。
      </p>
      <p>
        4.如果发现有用户使用任何作弊或不正当手段等方式参加本活动，主办方有权限制其每日参与次数或取消其参加活动的资格和兑换奖项资格且无需承担任何责任。
      </p>
      <p>
        5.未满18周岁的消费者需在家长（即法定监护人）的陪伴下参加本次活动并行使相关权利。
      </p>
      <p>
        6.若对本活动有任何咨询或意见，请拨打费列罗消费者热线: 400-880-6266 (
        9:00－18:00,节假日除外)。
      </p>
    </>
  );
}
