const requireContext = require.context(
  "../assets",
  true,
  /\.(png|jpg|svg|webp)$/
);

const requireAll = (context) => context.keys().map(context);
export const AssetsImages = requireAll(requireContext);

//预加载图片
export async function PreLoadImages(paths) {
  let imageFetchCount = 0;

  return new Promise((reoslve, reject) => {
    paths.forEach((path) => {
      let i = new Image();
      i.src = path;
      i.onload = function () {
        imageFetchCount++;
        if (imageFetchCount >= paths.length) {
          reoslve(true);
        }
      };

      i.onerror = function (e) {
        reject(e);
      };
    });
  });
}

export function IsRegistered() {
  return sessionStorage.getItem("memberid") ? true : false;
}

// 导出一个名为GoRegister的函数
export function GoRegister(
  url = "/pages/userPackage/FY2324_CNY_BeSpoke_Register"
) {
  console.log("小程序跳转路径是", url);
  // 调用微信小程序的API，跳转到指定的页面
  window.wx.miniProgram.navigateTo({
    url, // 路径为传入的url参数值
    fail: function (err) {
      console.log("navigateTo error info is", err);
    },
    complete: function () {
      console.log("navigateTo is complete");
    },
  });
}

/**
 *
 * @function parseLinkWithHash
 * @param {string} link - The link to be parsed.
 * @returns {Object} - An object containing the parameters from the link.
 */
export function parseLinkWithHash(link) {
  const linkString = link;
  const urlSearchParams = new URLSearchParams(linkString.split("#")[1]);
  const params = {};
  urlSearchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
}

//默认的模版视频。
export const DEFAULT_VIDEO_TEMPLATE_URL =
  "https://kinder-bucket.oss-cn-shanghai.aliyuncs.com/videos/template/video1123.mp4";

//获取中文
export function getChineseChars(str) {
  const regex = /[\u4e00-\u9fa5]/g;
  return str.match(regex);
}

//数组根据key 去
export function removeDuplicates(lst, key) {
  return lst.filter(
    (obj, index, self) => index === self.findIndex((t) => t[key] === obj[key])
  );
}

//是否首次加载
export function isFirstLoad(active_name = "activity_1") {
  const isFirstLoad = localStorage.getItem(`is_first_load_${active_name}`)
    ? true
    : false;
  localStorage.setItem(`is_first_load_${active_name}`, "1");
  return isFirstLoad;
}

export function getSecondMoveVideo(channel) {
  const DAYS_WITCH_VIDEO = {
    Day1: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day1.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day1.mp4",
    },
    Day2: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day2.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day2.mp4",
    },
    Day3: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day3.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day3.mp4",
    },
    Day4: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day4.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day4.mp4",
    },
    Day5: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day5.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day5.mp4",
    },
    Day6: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day6.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day6.mp4",
    },
    Day7: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day7.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day7.mp4",
    },
    Day8: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day8.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day8.mp4",
    },
    Day9: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day9.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day9.mp4",
    },
    Day10: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day10.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day10.mp4",
    },
    Day11: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day11.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day11.mp4",
    },
    Day12: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day12.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day12.mp4",
    },
    Day13: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day13.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day13.mp4",
    },
    Day14: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day14.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day14.mp4",
    },
    Day15: {
      poster: "https://kinder-bucket.hawnlink.cn/cny/v3/Day15.png",
      url: "https://kinder-bucket.hawnlink.cn/cny/v3/Day15.mp4",
    },
  };

  return DAYS_WITCH_VIDEO[channel] || DAYS_WITCH_VIDEO.Day1;
}

//跳转到小程序获取积分页
export function goToMiniPoints(path) {
  window.wx.miniProgram.navigateTo({
    url: "/pages/userPackage/points",
  });
}

//获取渠道
export function getChannel() {
  return localStorage.getItem("channel") || "";
}

export function getCode() {
  return sessionStorage.getItem("code") || "";
}

export function navigateToMiniHome(url, callback) {
  if (isMini() && window.wx.miniProgram) {
    window.wx.miniProgram.navigateTo({
      url,
    });
  } else {
    callback();
  }
}

export function isMini() {
  const ua = navigator.userAgent;
  if (ua.includes("miniProgram")) {
    return true;
  } else {
    return false;
  }
}
