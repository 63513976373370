export default function Activity1Rules() {
  return (
    <>
      <p style={{ textAlign: "center" }}>健达2024年新年祝福定制视频活动规则</p>
      <br />

      <p>活动主题：健达2024年新年祝福视频定制</p>
      <p>活动时间：2023年12月01日至2024年02月29日 </p>
      <br />

      <p>活动方式</p>
      <p>
        1.
        活动期间，用户扫码进入本活动页面，即可按页面提示定制新春祝福视频。健达会员还可将生成的祝福视频分享给微信好友；非会员请注册成为健达会员后，进行分享。
      </p>
      <p>2. 新春祝福视频将于【活动结束】后被删除并将无法查看。</p>

      <br />
      <p>其他</p>
      <p>1. 本次活动主办方为费列罗贸易（上海）有限公司（统一简称“主办方”）。</p>
      <p>
        2.
        活动参与者应仔细阅读本活动须知，活动参与者注册成为健达会员，或点击“生成视频”按钮参与本活动则视为已阅读、知晓并同意遵守本次活动规则。
      </p>
      <p>
        3.
        主办不对因网络传输原因而导致参加者提交的信息错误承担任何责任。如遇不可抗力、网络突发故障、系统发生故障或遭受第三方攻击及其他主办方无法控制的情形，在法律允许的范围内，主办方对前述情形所导致的一后果不承担任何责任，并有权相应地取消、终止、修改、暂停或延迟本次活动。
      </p>
      <p>
        4.
        如果发现有用户使用任何作弊或不正当手段等方式参加本活动，主办方有权限制其每日参与次数或取消其参加活动的资格且无需承担任何责任。
      </p>
      <p>
        5.
        未满18周岁的消费者需在家长（即法定监护人）的陪伴下参加本次活动并行使相关权利。
      </p>
      <p>
        6. 若对本活动有任何咨询或意见，请拨打费列罗消费者热线: 400-880-6266 (
        9:00－18:00,节假日除外)。
      </p>

      <br />

      <p>提交作品须知：</p>
      <p>
        所有参加者一旦上传自己的作品，则被视为接受本次活动的所有规定，包括：
      </p>
      <p>
        您一旦上传作品（包括但不限于照片、文字或视频），即被视为该作品的作者或已就该提交该作品及其中包含的个人信息（如有）取得了合法授权和同意，且该作品未经发表或出版，以及该作品不会侵犯他人著作权、肖像权、名誉权和其他合法权益。如发现任何侵犯他人合法权益的行为，将被取消参加活动及获奖资格；如因此引发任何侵权及相关法律责任，将由您本人承担，主办方将不为此承担任何责任。
      </p>
      <p>
        您同意您上传的作品一旦被主办方选中，视为同意授权主办方及其关联公司在本次活动网站、主办方官网、APP、微博、微信以及网络推广等途径使用，并授权主办方及其关联公司享有对该作品的复制权及改编权，且主办方无须就以上的使用事宜向您支付任何费用。{" "}
      </p>
      <p>
        非主办方之原因，参加者上传作品之行为所引发的任何纠纷与主办方无关，由其自行承担，若对主办方造成损失的主办方有权要求其进行赔偿。
      </p>
      <p>
        主办方将对您上传的作品进行审核，如有任何违反法律法规、本活动的有关规定、以及任何主办方认为不适合在本活动中出现的内容，主办方将立即将其删除或屏蔽，且不需要对您另行通知。
      </p>
    </>
  );
}
