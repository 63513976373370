import "./App.css";

import { Navigate, Route, Routes } from "react-router-dom";

import asyncComponent from "./plugin/AsyncComponent";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { userLogin } from "@api";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { urlLog } from "./api";
import useRules from "./plugin/Rules";
import { isMini } from "./utils";

const ActivityStart1 = asyncComponent(() => import("@pages/activity1/Start"));
const ActivityHome1 = asyncComponent(() => import("@pages/activity1/Home"));
const ActivityCreate1 = asyncComponent(() => import("@pages/activity1/Create"));
const ActivityCreate2 = asyncComponent(() =>
  import("@pages/activity1/Create2")
);
const ActivityLoading1 = asyncComponent(() =>
  import("@pages/activity1/Loading")
);
const ActivityVideo1 = asyncComponent(() => import("@pages/activity1/Video"));

const ActivityStart2 = asyncComponent(() => import("@pages/activity2/Start"));
const ActivityHome2 = asyncComponent(() => import("@pages/activity2/Home"));
const ActivityResult2 = asyncComponent(() => import("@pages/activity2/Result"));
const ActivityWriteOff2 = asyncComponent(() =>
  import("@pages/activity2/WriteOff")
);
const ActivityMyAward2 = asyncComponent(() =>
  import("@pages/activity2/MyAward")
);

const ActivityStart3 = asyncComponent(() => import("@pages/activity3/Start"));
const ActivityHome3 = asyncComponent(() => import("@pages/activity3/Home"));
const ActivitySign3 = asyncComponent(() => import("@pages/activity3/Sign"));

const DebugPage = asyncComponent(() => import("@pages/debug"));

//模拟小程序进入， 请追加参数 （详见readme.md） {host}?openId=OXRHM0tBV0hlQzVnTTF0dUthT3YyOVVCQzBvZVlnWTUrU3JBeWE4ZkZuST0&channel=FY2324CNYBeSpoke#memberId=7a6fe9743f3043c7847c4de8c6869ad7
//查询用户信息
userLogin();

function PrivateRoute({ Component, ...rest }) {
  const search = new URLSearchParams(window.location.search);
  const taskId = search.get("taskId");
  const shareOpenid = search.get("shareOpenid");
  const memberId = sessionStorage.getItem("memberid");

  return taskId && shareOpenid ? (
    <Navigate to={`/activity_video_1?taskId=${taskId}&isShare=1`} replace />
  ) : (
    <ActivityStart1 />
  );
}

function App() {
  // Create a client
  const queryClient = new QueryClient();
  const location = useLocation();
  const { renderNode: renderRuleNode, open: openRule } = useRules();

  useEffect(() => {
    console.log("当前location 是", location);
    urlLog(); //记录一下当前地址
  }, [location]);

  if (!isMini() && process.env.NODE_ENV !== "development")
    return (
      <p
        style={{
          textAlign: "center",
          color: "white",
          paddingTop: 100,
        }}
      >
        请在小程序内打开
      </p>
    );

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<PrivateRoute />}></Route>
          <Route path="/activity_start_1" element={<ActivityStart1 />}></Route>
          <Route path="/activity_home_1" element={<ActivityHome1 />}></Route>
          <Route
            path="/activity_create_1"
            element={<ActivityCreate1 />}
          ></Route>
          <Route
            path="/activity_create_2"
            element={<ActivityCreate2 />}
          ></Route>
          <Route
            path="/activity_loading_1"
            element={<ActivityLoading1 />}
          ></Route>
          <Route path="/activity_video_1" element={<ActivityVideo1 />}></Route>
          <Route path="/activity_start_2" element={<ActivityStart2 />}></Route>
          <Route path="/activity_home_2" element={<ActivityHome2 />}></Route>
          <Route
            path="/activity_result_2"
            element={<ActivityResult2 />}
          ></Route>
          <Route
            path="/activity_write_off_2"
            element={<ActivityWriteOff2 />}
          ></Route>
          <Route
            path="/activity_my_award_2"
            element={<ActivityMyAward2 />}
          ></Route>

          <Route path="/activity_start_3" element={<ActivityStart3 />}></Route>
          <Route path="/activity_home_3" element={<ActivityHome3 />}></Route>
          <Route path="/activity_sign_3" element={<ActivitySign3 />}></Route>

          {/* <Route path="/debug" element={<DebugPage />}></Route> */}
        </Routes>
        {renderRuleNode()}
      </QueryClientProvider>

      {/* <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 9999,
          backgroundColor: "black",
          color: "white",
          fontSize: 12,
          padding: 5,
        }}
        onClick={() => {
          window.location.href = "/debug";
        }}
      >
        debug
      </div> */}
    </div>
  );
}

export default App;
