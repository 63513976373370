import axios from "axios";
import { Toast } from "react-vant";
import { userLogin } from ".";
import { getChannel, getCode } from "../utils";

const http = axios.create();
http.defaults.timeout = 10000;

// 请求拦截
http.interceptors.request.use(
  async (config) => {
    const openid = localStorage.getItem("openid") || "";
    const memberid = sessionStorage.getItem("memberid") || "";
    const channel = getChannel();
    const code = getCode();
    const sendPoint = sessionStorage.getItem("send_point") || "0";

    config.headers["openid"] = openid;
    config.headers["memberid"] = memberid;
    config.headers["channel"] = channel;
    config.headers["code"] = code;
    config.headers["sendPoint"] = sendPoint;

    //强制登录
    if (!localStorage.getItem("user_id")) {
      await userLogin();
    }

    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res?.msg === "用户错误") {
      localStorage.clear();
    }
    if (res?.state === "fail") res?.msg && Toast(res?.msg);

    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
