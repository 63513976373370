import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { Dialog } from "react-vant";
import { parseLinkWithHash } from "./utils";

// if (window.location.pathname.startsWith("/activity_start_3")) new VConsole();

//处理URL参数
const search = new URLSearchParams(window.location.search);
search.get("openId") && localStorage.setItem("openid", search.get("openId"));
search.get("channel") && localStorage.setItem("channel", search.get("channel"));
search.get("code") && sessionStorage.setItem("code", search.get("code"));
search.get("shareOpenid") &&
  sessionStorage.setItem("shareOpenid", search.get("shareOpenid"));

//是否发放过积分？
search.get("sendPoint") &&
  sessionStorage.setItem("send_point", search.get("sendPoint"));

const hashParmas = parseLinkWithHash(window.location.href);
hashParmas?.memberId &&
  sessionStorage.setItem("memberid", hashParmas?.memberId);

if (!localStorage.getItem("openid")) {
  Dialog.alert({
    title: "未携带openId",
    message: `当前地址：${window.location.href}`,
  });
}

//埋点配置
window._ha("create", "60793", {
  auto_track: false,
  url_change_track: true,
  env: { inst: "fero" },
  api_url: "//new-tracker.ferrero.com.cn",
});

// identify (认证用户信息)
window._ha("identify", {
  cust_id: hashParmas?.memberId || "",
  wx_id: search.get("openId"),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
