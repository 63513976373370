export default function Activity2Rules() {
  return (
    <>
      <p style={{ textAlign: "center" }}>“惊喜卡抽奖赢好礼”活动规则</p>
      <br />
      <p>一、活动主题</p>
      <p>惊喜卡抽奖赢好礼（以下称“本活动”）</p>
      <br />
      <p>二、活动时间</p>
      <p>2023年12月1日—2024年2月29日</p>
      <br />

      <p>三、奖品设置</p>
      <p>
        A．天真蓝全家福拍摄券兑换码1份：面值799元，可用于兑换1张5人全家福照片拍摄体验1次，具体请以天真蓝官方小程序产品详情页面公示为准，共计10份。
      </p>
      <p>
        B．凯叔讲故事会员年卡兑换码1份：面值348元，可用于兑换为期1年的凯叔讲故事会员，共计40份。
      </p>
      <p>
        C．淘票票300元乐影卡兑换码1份：可用于在淘票票APP、支付宝或手机淘宝的“淘票票电影”中使用，支持购买任意影院的电影票、卖品、影城卡等商品，暂不支持购买演出票，共计60份。
      </p>
      <p>
        D．爱奇艺黄金VIP会员季卡兑换码1份：面值78元，可用于兑换为期1个季度的爱奇艺黄金VIP会员，共计300份。
      </p>
      <p>E．健达定制微信红包封面1份，共计34,678份。</p>
      <br />

      <p>四、参与方式</p>
      <p>
        1.购买龙年版“健达新年分享桶巧克力制品”或“健达新年倒数日历装巧克力制品”（以下称“活动产品”），扫描内附的惊喜卡上二维码进入活动页面并点击“立即抽奖”按钮，即可参加一次即时随机抽奖活动，抽奖后即时显示结果信息。
      </p>
      <p>2. 每份活动产品中仅含一张惊喜卡，每张惊喜卡上的二维码仅限使用一次。</p>
      <br />

      <p>五、获奖须知</p>
      <p>
        1.奖项A、B、C、D：获奖者可在中奖页面点击“点击复制”或“复制卡号”“复制密码”
        复制兑换码，并在“活动规则”中查阅奖品兑换步骤；在活动页面进入“我的奖品”亦可查看相应的兑换码。请保留好印有抽奖二维码的惊喜卡，以便返回活动页面查看奖品。前述各奖项兑换步骤如下：{" "}
      </p>
      <br />

      <p>天真蓝拍摄券-5人全家福照兑换步骤：</p>
      <p>（1）微信搜索进入“天真蓝”小程序；</p>
      <p>（2）点击“我的”，授权登录；</p>
      <p>（3）点击“优惠券”-“添加新券“，输入券码，点击“添加”完成兑换； </p>
      <p
        style={{
          textDecoration: "underline",
        }}
      >
        （4）兑换及使用有效期：2024年12月31日23:59前；
      </p>
      <p>注：在兑换过程中如有任何问题，请洽“天真蓝”客服。</p>
      <br />

      <p>凯叔讲故事会员年卡兑换步骤：</p>
      <p>（1）关注“凯叔加油站”微信公众号进入对话框；</p>
      <p>（2）点击右下角“家长控制”-“兑换码”，授权登录；</p>
      <p>（3）输入16位兑换码，完成兑换；</p>
      <p>（4）下载“凯叔讲故事”APP，微信登录即可收听；</p>
      <p
        style={{
          textDecoration: "underline",
        }}
      >
        （5）兑换有效期：2024年11月23日23:59前；
      </p>
      <p>
        注：“凯叔讲故事”APP登录手机号和微信注册需为同一个手机号。在兑换过程中如有任何问题，请洽“凯叔讲故事”客服。
      </p>
      <br />

      <p>淘票票乐影卡兑换步骤：</p>
      <p>（1）下载“淘票票”APP并登录；</p>
      <p>（2）点击“我的”-在“我的服务”模块向右滑动，点击“乐影卡”；</p>
      <p>（3）勾选“我已阅读并同意《淘票票乐影卡章程》-点击“立即绑卡”；</p>
      <p>（4）输入卡号及密码，点击“立即绑卡”，完成兑换；</p>
      <p
        style={{
          textDecoration: "underline",
        }}
      >
        （5）兑换有效期：2026年11月30日00:00前；
      </p>
      <p>注：在兑换过程中如有任何问题，请洽“淘票票”客服。</p>
      <br />

      <p>爱奇艺黄金VIP会员季卡兑换步骤：</p>
      <p>（1）下载“爱奇艺”APP并登录；</p>
      <p>
        （2）在搜索框输入“兑换”并搜索，自动跳出兑换码激活会员入口，点击“进入激活页面”；{" "}
      </p>
      <p>（3）输入激活码，点击”立即激活“完成兑换；</p>
      <p
        style={{
          textDecoration: "underline",
        }}
      >
        （4）兑换有效期：2024年10月10日00:00前；
      </p>
      <p>注：在兑换过程中如有任何问题，请洽“爱奇艺”客服。</p>
      <br />

      <p>
        2.
        健达定制微信红包封面：点击“立即领取”或“立即领取奖品”按钮，即进入微信红包封面领取页面，获奖者可自行根据提示领取，红包封面有效期为领取后的3个月。
      </p>
      <p>
        3.
        所有奖项一经确认不得转换、转让、折换现金或其他权益。若在本活动结束后未声明奖品未获得，即视为已获得奖品。
      </p>
      <p>4. 奖品个人所得税由主办方代扣代缴。</p>
      <br />

      <p>六、其他</p>
      <p>1.本次活动主办方为费列罗贸易（上海）有限公司（统一简称“主办方”）。</p>
      <p>
        2.活动参与者应仔细阅读本活动规则。活动参与者注册成为健达会员，并于本活动页面上点击抽奖，即视为其已阅读、知悉并同意遵守本次活动规则。
      </p>
      <p>
        3.主办方不对因网络传输原因、活动参加者提交信息失败或错误承担任何责任。
        如遇不可抗力、网络突发故障、系统发生故障或遭受第三方攻击及其他主办方无法控制的情形，主办方在法律允许的范围内拥有决定取消、终止、修改或暂停本活动的权利，无需承担任何责任。
      </p>
      <p>
        4.如果发现有活动参与者使用任何作弊或不正当手段等方式参加本活动，主办方有权限制或取消其参加活动的资格和中奖、兑奖资格且无需承担任何责任。
      </p>
      <p>
        5.参加本活动的消费者应当年满18周岁
        。如未满18周岁，必须由家长（即法定监护人）参加本次活动，并行使相关权利。
      </p>
      <p>
        6.如有任何咨询或意见，请拨打费列罗消费者热线: 400-880-6266 (
        9:00－18:00,节假日除外，仅限中国大陆)。
      </p>
    </>
  );
}
