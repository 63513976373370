import dayjs from "dayjs";
import { IsRegistered, getChannel, getCode } from ".";

/**
 * 打开弹窗的函数
 *
 * @param {string} name - 弹窗名称
 * @param {string} active_name - 当前活动名称
 * @param {string} campaign_id - 营销活动ID，默认为CampaignIds.activity1
 */
export function HaPopupOpen(
  name,
  active_name,
  campaign_id = CampaignIds.activity1
) {
  const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
  sessionStorage.setItem("popup_opened_start_time", `${now}`);

  window._ha("send", "action", active_name, {
    popupwindow_name: name, // 弹窗名称
    from_page: window.location.href, // 来自哪个页面
    open_time: now, // 打开弹窗的时间
    campaign_id, // 营销活动ID
    channel: getChannel(), // 渠道信息
    code: getCode(),
  });
}

export function HaPopupClosed(
  name,
  active_name,
  campaign_id = CampaignIds.activity1
) {
  const open_time = sessionStorage.getItem("popup_opened_start_time") || "";
  window._ha("send", "action", active_name, {
    campaign_id,
    channel: getChannel(),
    popupwindow_name: name,
    from_page: window.location.href,
    open_time,
    close_time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    view_duration: dayjs().diff(dayjs(open_time), "seconds"),
    code: getCode(),
  });
}

export const HaGiftsMap = {
  1: "tianzhenlan",
  2: "kaishu",
  3: "taopiaopiao",
  4: "iqiyi",
  5: "hongbao",
};
export function setGiftType(id) {
  sessionStorage.setItem("gift_type", HaGiftsMap[id] || "");
}

export function getGiftType() {
  return sessionStorage.getItem("gift_type") || "";
}

export function GoToReceiveClick(gifname) {
  window._ha("send", "action", "cny_activity2_receivepage_gotoreceive_click", {
    campaign_id: CampaignIds.activity2,
    channel: getChannel(),
    code: getCode(),
    gift_type: gifname,
    is_member: IsRegistered() ? "member" : "notmember",
  });
}

export const CampaignIds = {
  activity1: "FY2324_Kinder Seasonal_Besopke Video",
  activity2: "FY2324_Kinder Seasonal_Lucky Draw",
  activity3: "FY2324_Kinder Seasonal_Countdown Calendar",
};

export function getHaPageTypeWithShare() {
  const search = new URLSearchParams(window.location.search);
  const shareOpenid = search.get("shareOpenid");
  return shareOpenid
    ? "ShareVideoPage"
    : IsRegistered()
    ? "MemberVideoPage"
    : "NotMemberVideoPage";
}
