import { getChannel, getCode } from "../utils";
import request from "./request";

/**
 * 用户登录, 查询用户信息
 *
 */
export const userLogin = async () => {
  const openid = localStorage.getItem("openid") || "";
  const memberid = sessionStorage.getItem("memberid") || "";
  const channel = getChannel();
  const code = getCode();
  const res = await fetch(`/api/cny/info`, {
    headers: {
      openid,
      memberid,
      channel,
      code,
    },
  }).then((res) => res.json());

  //缓存用户id，表示用户注册了， 进行用户相关行为的时候可能会需要

  if (res.data && res.data.id) localStorage.setItem("user_id", res.data.id);
  return Promise.resolve(res.data);
};

/**
 * 创建视频
 *
 * @param text 空值
 * @param nick 空值
 * @param greeting 空值
 * @param v1 角色的 id
 * @param v2 标签的 id
 * @return
 */

export const complexVideo = async (params) => {
  return await request.post("/api/cny/complexVideo", params);
};

/**
 *
 * @param taskId
 * @return
 */
export const getVideoRender = async (params) => {
  return await request.get("/api/cny/videoResult", {
    params,
  });
};

/**
 *
 * 获取奖品列表
 */
export const getAwardList = async () => {
  return await request.get("/api/cny/rewards");
};

/**
 *
 * 抽奖
 */

export const doLucky = async () => {
  return await request.post(`/api/cny/lucky`);
};

/**
 *
 * 返回中奖列表
 */

export const getRewardLog = async () => {
  return await request.get(`/api/cny/rewardLog`);
};

/**
 * 获取中奖记录详情
 *
 * @param id sting
 * @return
 */
export const getRewardLogDetial = async (params) => {
  return request.get("/api/cny/detail", { params });
};

/**
 * 校验串码
 *
 * @param code sting
 * @return status  0=可以使用 1=自己已使用 2=其他人已使用
 */
export const checkCode = async (params) => {
  return await request.get(`/api/cny/checkCode`, { params });
};

/**
 * 获取后台配置项
 *
 * @param code sting
 * @return string
 */
export const getConfigData = async (params) => {
  return await request.get(`/api/cny/config`, { params });
};

/**
 * 提交妙招
 *
 * @param code sting
 * @return string
 */
export const submitDay = async (params) => {
  return await request.post(`/api/cny/submitDay`, { ...params });
};

/**
 * 获取妙招
 *
 * @param
 * @return
 */
export const getDayList = async () => {
  return await request.get(`/api/cny/dayList`);
};

/**
 * 是否已领取积分
 *
 * @param
 * @return
 */
export const getHasPoint = async () => {
  return await request.get(`/api/cny/hasPoint`);
};

export const urlLog = async () => {
  return request.post(`/api/cny/miniUrl`, {
    url: window.location.href,
    env: navigator.userAgent,
  });
};

// 发放积分
export const SendPoint = async () => {
  return request.post(`/api/cny/sendPoint`);
};
