import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Overlay } from "react-vant";
import { IsRegistered, getChannel, getCode } from "../../utils";
import {
  CampaignIds,
  HaPopupClosed,
  HaPopupOpen,
  getGiftType,
} from "../../utils/ha.common";
import Activity1Rules from "./components/Activity1Rules";
import Activity2Rules from "./components/Activity2Rules";
import Activity3Rules from "./components/Activity3Rules";
import styles from "./index.module.scss";

const hiddenPathnames = [
  "/",
  "/activity_start_2",
  "/activity_start_3",
  "/activity_loading_1",
  "/debug",
];
export default function useRules() {
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  console.log("活动规则，当前path", location.pathname);

  function close() {
    setVisible(false);
    if (
      ["/activity_home_1", "/activity_create_2", "/activity_video_1"].includes(
        location.pathname
      )
    )
      HaPopupClosed(
        "ActivityRulePopupWindow",
        "cny_activity1_popupwindow_close",
        CampaignIds.activity1
      );

    if (
      [
        "/activity_home_2",
        "/activity_write_off_2",
        "/activity_result_2",
        "/activity_my_award_2",
      ].includes(location.pathname)
    ) {
      HaPopupClosed(
        "ActivityRulePopupWindow",
        "cny_activity2_popupwindow_close",
        CampaignIds.activity2
      );
    }

    if (["/activity_home_3", "/activity_sign_3"].includes(location.pathname)) {
      HaPopupClosed(
        "ActivityRulePopupWindow",
        "cny_activity3_popupwindow_close",
        CampaignIds.activity3
      );
    }
  }

  function renderNode() {
    return (
      <>
        {!hiddenPathnames.includes(location.pathname) && (
          <button className={styles.button} onClick={() => open()} />
        )}
        <Overlay
          zIndex={99}
          onClick={() => {
            close();
          }}
          visible={visible}
          className={styles.overlayContianer}
        >
          <div className={styles.container}>
            <i
              onClick={() => {
                close();
              }}
              className={styles.close}
            ></i>

            <div className={styles.content}>
              {[
                "/activity_home_1",
                "/activity_create_1",
                "/activity_create_2",
                "/activity_video_1",
              ].includes(location.pathname) && <Activity1Rules />}

              {[
                "/activity_home_2",
                "/activity_result_2",
                "/activity_write_off_2",
                "/activity_my_award_2",
              ].includes(location.pathname) && <Activity2Rules />}

              {["/activity_home_3", "/activity_sign_3"].includes(
                location.pathname
              ) && <Activity3Rules />}
            </div>
          </div>
        </Overlay>
      </>
    );
  }

  function open() {
    setVisible(true);

    if (location.pathname === "/activity_create_2") {
      window._ha(
        "send",
        "action",
        "cny_activity1_blessingtypepage_rule_click",
        {
          campaign_id: CampaignIds.activity1,
          channel: getChannel(),
        }
      );
    }

    //CNY_活动一_视频页_点击【活动规则】 CNY_Activity1_VideoPage_Rule_Click
    if (location.pathname === "/activity_video_1") {
      const search = new URLSearchParams(window.location.search);
      const shareOpenid = search.get("shareOpenid");
      window._ha("send", "action", "cny_activity1_videopage_rule_click", {
        campaign_id: CampaignIds.activity1,
        channel: getChannel(),
        page_type: shareOpenid
          ? "ShareVideoe"
          : IsRegistered()
          ? "MemberVideoPage"
          : "NotMemberVideoPage",
      });
    }

    if (location.pathname === "/activity_home_1") {
      window._ha("send", "action", "cny_activity1_homepage_rule_click", {
        campaign_id: CampaignIds.activity1,
        channel: getChannel(),
      });
    }

    if (location.pathname === "/activity_my_award_2") {
      window._ha("send", "action", "cny_activity2_mygiftpage_rule_click", {
        channel: getChannel(),
        campaign_id: CampaignIds.activity2,
      });
    }

    if (
      location.pathname === "/activity_home_2" ||
      location.pathname === "/activity_write_off_2" ||
      location.pathname === "/activity_result_2" ||
      location.pathname === "/activity_my_award_2"
    ) {
      HaPopupOpen(
        "ActivityRulePopupWindow",
        "cny_activity2_popupwindow_open",
        CampaignIds.activity2
      );
    }

    if (location.pathname === "/activity_home_2") {
      window._ha("send", "action", "cny_activity2_homepage_rule_click", {
        campaign_id: CampaignIds.activity2,
        channel: getChannel(),
        code: getCode(),
      });
    }

    if (location.pathname === "/activity_write_off_2") {
      window._ha("send", "action", "cny_activity2_usedpage_rule_click", {
        campaign_id: CampaignIds.activity2,
        channel: getChannel(),
        code: getCode(),
        is_personnal_use: sessionStorage.getItem("is_personnal_use"),
      });
    }

    if (location.pathname === "/activity_result_2") {
      window._ha("send", "action", "cny_activity2_receivepage_rule_click", {
        gift_type: getGiftType(),
        is_member: IsRegistered() ? "member" : "member",
        campaign_id: CampaignIds.activity2,
        channel: getChannel(),
        code: getCode(),
      });
    }

    if (
      location.pathname === "/activity_home_1" ||
      location.pathname === "/activity_create_2" ||
      location.pathname === "/activity_video_1"
    ) {
      HaPopupOpen(
        "ActivityRulePopupWindow",
        "cny_activity1_popupwindow_open",
        CampaignIds.activity1
      );
    }

    if (location.pathname === "/activity_home_3") {
      window._ha("send", "action", "cny_activity3_h5couppage_rule_click", {
        campaign_id: CampaignIds.activity3,
        channel: getChannel(),
      });
    }

    if (location.pathname === "/activity_sign_3") {
      window._ha("send", "action", "cny_activity3_collectcouppage_rule_click", {
        campaign_id: CampaignIds.activity3,
        page_type:
          sessionStorage.getItem("activity3_page_type") || "not all collected",
      });
    }

    if (["/activity_home_3", "/activity_sign_3"].includes(location.pathname)) {
      HaPopupOpen(
        "ActivityRulePopupWindow",
        "cny_activity3_popupwindow_open",
        CampaignIds.activity3
      );
    }

    if (location.pathname === "/activity_home_3")
      window._ha("send", "action", "cny_activity3_miniappcouppage_rule_click", {
        campaign_id: CampaignIds.activity3,
        channel: getChannel(),
      });
  }

  return {
    renderNode,
    open,
  };
}
