import React, { Component } from "react";
export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        comp: null
      };
    }
    async componentDidMount() {
      const { default: comp } = await importComponent();
      this.setState({comp});
    }
    render() {
      const C = this.state.comp;
      return C ? <C {...this.props} /> : <></>
      ;
    }
  }
  return AsyncComponent;
}